<template>
  <v-dialog v-model="approveDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class=" headline" :hidden="approve_excuses">{{ $t("Approve") }}</span>
        <span class=" headline" :hidden="price_plan">{{ $t("Fee Plan") }}</span>
        <span class=" headline" :hidden="confirmation_message">
          {{ $t("Confirmation Message") }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row :hidden="approve_excuses">
            <v-col cols="12" sm="12" md="12" :class="{ hidden: data.in_range }">
              <label>
                {{ $t("Acceptance Age Exception Reason") }}
              </label>
              <v-select
                :items="[
                  { title: 'Please Select Reason', value: '' },
                  {
                    title: 'Exception from ministry regarding age requirement',
                    value: 'Exception from ministry regarding age'
                  },
                  {
                    title: 'Exception from Administration',
                    value: 'Exception from Administration'
                  },
                  {
                    title: 'Under the guardian – Administration Agreement',
                    value: 'Under the guardian – Administration Agreement'
                  },
                  { title: 'Others', value: 'Other' }
                ]"
                item-text="title"
                item-value="value"
                v-model.trim="$v.excuses[0].reason.$model"
                solo
                @change="onOther()"
              >
              </v-select>

              <p class="red--text acceptance_age_error" :hidden="R1">
                {{ $t("This field is required") }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="$v.excuses[0].reason.$model == 'Other'"
            >
              <label>{{ $t("Please enter reason") }}</label>
              <v-textarea
                v-model.trim="excuses[0].reason_other"
                @keyup="reason1ShowOther"
                solo
              ></v-textarea>

              <p
                class="red--text acceptance_age_error_other"
                :hidden="reason1Show"
              >
                {{ $t("This field is required") }}
              </p>
            </v-col>

            <v-col cols="12" sm="12" md="12" :hidden="data.iqama_in_range">
              <label>
                {{ $t("Iqama Exception Reason") }}
              </label>
              <v-select
                :items="[
                  { title: 'Please Select Reason', value: '' },
                  {
                    title: 'Under renewal',
                    value: 'Under renewal'
                  },
                  {
                    title: 'A pledge from the parents to renew the Iqama',
                    value: 'A pledge from the parents to renew the Iqama'
                  },
                  {
                    title: 'The son or daughter of an employee',
                    value: 'The son or daughter of an employee'
                  },
                  { title: 'Others', value: 'Other' }
                ]"
                item-text="title"
                item-value="value"
                v-model.trim="$v.excuses[1].reason.$model"
                solo
                @change="onOther2()"
              >
              </v-select>

              <p class="red--text iqama_error" :hidden="R2">
                {{ $t("This field is required") }}
              </p>

              <div v-if="$v.excuses[1].reason.$model == 'Other'">
                <label>{{ $t("Please enter reason") }}</label>
                <v-textarea
                  v-model.trim="excuses[1].reason_other"
                  @keyup="reason2ShowOther"
                  solo
                ></v-textarea>
              </div>
              <p class="red--text iqama_error_other" :hidden="reason2Show">
                {{ $t("This field is required") }}
              </p>
            </v-col>
          </v-row>

          <v-row :hidden="price_plan">
            <v-col cols="12" sm="12" md="12">
              <label>
                {{ $t("Fee Plan") }}
              </label>
              <v-select
                :items="plans"
                item-text="title"
                item-value="id"
                v-model.trim="$v.pricePlan.price_plan_id.$model"
                solo
                @change="onSelect()"
              >
              </v-select>

              <p
                class="red--text"
                v-if="
                  $v.pricePlan.price_plan_id.$error &&
                    !$v.pricePlan.price_plan_id.required
                "
              >
                {{ $t("This filed is required") }}
              </p>
              <p class="red--text fee_plan_error" :hidden="R3">
                {{ $t("This field is required") }}
              </p>
            </v-col>

            <v-col cols="12" sm="12" md="12" :hidden="Reasonhidden">
              <label>
                {{ $t("Fee Plan Change Reason") }}
              </label>
              <v-select
                :items="[
                  { title: 'Please Select Reason', value: '' },
                  {
                    title: 'Exception from Administration',
                    value: 'Exception from Administration'
                  },
                  {
                    title: 'The son or daughter of an employee',
                    value: 'The son or daughter of an employee'
                  },
                  { title: 'Others', value: 'Other' }
                ]"
                item-text="title"
                item-value="value"
                v-model.trim="$v.pricePlan.price_plan_reason.$model"
                solo
                @change="onOther3()"
              >
              </v-select>
              <p class="red--text fee_plan_reason_error" :hidden="reason3Show">
                {{ $t("This filed is required") }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="$v.pricePlan.price_plan_reason.$model == 'Other'"
            >
              <label>{{ $t("Please enter reason") }}</label>
              <v-textarea
                v-model.trim="$v.pricePlan.price_plan_reason_other.$model"
                solo
                @keyup="reason3ShowOther"
              ></v-textarea>

              <p class="red--text fee_plan_reason_other_error" :hidden="R4">
                {{ $t("This field is required") }}
              </p>
            </v-col>
          </v-row>

          <v-row :hidden="confirmation_message">
            <v-col cols="12" sm="12" md="12">
              <p>
                {{ $t("The selected fee plan") }}
                <span v-if="plans.length > 0">
                  <!-- {{
                    plans.find(plan => plan.id === pricePlan.price_plan_id)
                      .title
                  }} -->
                </span>
                {{ $t("will be assigned to this applicant. Are you sure you want to continue?")}}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn
          :hidden="confirmation_message"
          class="modal-btn-save previous"
          @click="PreviousP"
        >
          {{ $t("Previous") }}
        </v-btn>
        <v-btn
          :hidden="price_plan_previous"
          class="modal-btn-save previous"
          @click="Previous"
        >
          {{ $t("Previous") }}
        </v-btn>
        <v-btn class="modal-btn-cancel" @click="close">
          {{ $t("Close") }}
        </v-btn>
        <v-btn
          :hidden="approve_excuses"
          class="modal-btn-save"
          @click="submitData"
        >
          {{ $t("Next") }}
        </v-btn>

        <v-btn
          :hidden="price_plan"
          class="modal-btn-save"
          @click="confirmationMessage"
        >
          {{ $t("Finish") }}
        </v-btn>
        <v-btn
          :hidden="confirmation_message"
          class="modal-btn-save"
          @click="submitPricePlanData"
          :disabled="disabledConfirmbtn"
          :loading="disabledConfirmbtn"
        >
          {{ $t("Confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "validateApplicant",
  props: ["data", "approveDialog"],
  data() {
    return {
      price_paln_id: "",
      loading: false,
      modal1: "",
      approve_excuses: false,
      reason1Show: true,
      reason2Show: true,
      reason3Show: true,
      R1: true,
      R2: true,
      R3: true,
      R4: true,
      price_plan_previous: true,
      price_plan: true,
      confirmation_message: true,
      modalOpened: false,
      other1: false,
      other2: false,
      other3: false,
      Reasonhidden: true,
      disabledSubmitbtn: false,
      disabledConfirmbtn: false,
      applicant: [],
      excuses: [
        {
          type: "acceptance_age_exception",
          reason: "",
          reason_other: ""
        },
        {
          type: "iqama_exception",
          reason: "",
          reason_other: ""
        }
      ],
      pricePlan: {
        price_plan_id: "",
        price_plan_reason: "",
        price_plan_reason_other: ""
      },
      plans: []
    };
  },
  validations: {
    excuses: {
      0: {
        type: { required },
        reason: { required },
        reason_other: {}
      },
      1: {
        type: { required },
        reason: { required },
        reason_other: {}
      }
    },
    pricePlan: {
      price_plan_id: { required },
      price_plan_reason: {},
      price_plan_reason_other: {}
    }
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
    applicantData: {
      get() {
        return this.data;
      },
      set(applicant) {
        return applicant;
      }
    }
  },
  async mounted() {
    await axios
      .get(this.getApiUrl + "/getDefaultFeePlan", {
        headers: {
          Authorization: "Bearer " + localStorage.token
          //the token is a variable which holds the token
        }
      })
      .then(response => {
        if (response.data.status.error == false) {
          this.price_paln_id = response.data.data.id;
        }
      });

    this.pricePlan.price_plan_id = this.price_paln_id;

    if (this.data.in_range == true && this.data.iqama_in_range == true) {
      // console.log(this.applicant.status);

      this.price_plan_previous = true;
      this.price_plan = false;
      this.approve_excuses = true;
      this.confirmation_message = true;
    } else {
      this.price_plan_previous = true;
      this.price_plan = true;
      this.approve_excuses = false;
      this.confirmation_message = true;
    }
    axios
      .get(this.getApiUrl + "/getfeesPlans", {
        headers: {
          Authorization: "Bearer " + localStorage.token
          //the token is a variable which holds the token
        }
      })
      .then(response => {
        if (response.data.status.error == false) {
          this.plans = response.data.data;
        }
      })
      .catch(err => {
        alert(err);
      });
  },
  methods: {
    close() {
      this.excuses = [
        {
          type: "acceptance_age_exception",
          reason: "",
          reason_other: ""
        },
        {
          type: "iqama_exception",
          reason: "",
          reason_other: ""
        }
      ];
      this.pricePlan = {
        price_plan_id: this.price_paln_id,
        price_plan_reason: "",
        price_plan_reason_other: ""
      };
      this.other1 = false;
      this.other2 = false;
      this.other3 = false;
      if (
        this.data.in_range &&
        this.data.in_range == true &&
        this.data.iqama_in_range &&
        this.data.iqama_in_range == true
      ) {
        // console.log(this.applicant.status);

        this.price_plan_previous = true;
        this.price_plan = false;
        this.approve_excuses = true;
        this.confirmation_message = true;
      } else {
        this.price_plan_previous = true;
        this.price_plan = true;
        this.approve_excuses = false;
        this.confirmation_message = true;
      }

      this.R1 = true;
      this.R2 = true;
      this.R3 = true;
      this.R4 = true;
      this.reason1Show = true;
      this.reason2Show = true;
      this.reason3Show = true;
      this.Reasonhidden = true;
      this.approveDialog = false;
      this.$v.$reset();
      this.$emit("childToParent", false, false);
    },
    reason1ShowOther() {
      if (this.excuses[0].reason_other == "") {
        this.reason1Show = false;
      } else {
        this.reason1Show = true;
      }
    },
    reason2ShowOther() {
      if (this.excuses[1].reason_other.length == "") {
        this.reason2Show = false;
      } else {
        this.reason2Show = true;
      }
    },
    reason3ShowOther() {
      if (this.pricePlan.price_plan_reason_other.length == "") {
        this.R4 = false;
      } else {
        this.R4 = true;
      }
    },
    confirmationMessage() {
      if (
        this.$v.pricePlan.$invalid ||
        this.reason3Show == false ||
        (this.pricePlan.price_plan_id != this.price_paln_id &&
          this.pricePlan.price_plan_reason == "") ||
        (this.pricePlan.price_plan_reason == "Other" &&
          this.pricePlan.price_plan_reason_other == "")
      ) {
        if (
          this.pricePlan.price_plan_id != this.price_plan_id &&
          this.pricePlan.price_plan_reason == ""
        ) {
          this.reason3Show = false;
        } else {
          this.reason3Show = true;
        }
        if (
          this.pricePlan.price_plan_reason == "Other" &&
          this.pricePlan.price_plan_reason_other == ""
        ) {
          this.R4 = false;
        } else {
          this.R4 = true;
        }
      } else {
        this.price_plan = true;
        this.price_plan_previous = true;
        this.approve_excuses = true;
        this.confirmation_message = false;
      }
    },
    onSelect() {
      this.pricePlan.price_plan_reason = "";
      this.pricePlan.price_plan_reason_other = "";
      this.other3 = false;
      this.R4 = true;
      if (
        this.pricePlan.price_plan_id != this.price_paln_id &&
        this.pricePlan.price_plan_id != ""
      ) {
        // console.log("price" + this.pricePlan.price_plan_id);
        // console.log(this.price_paln_id);
        this.Reasonhidden = false;
        this.R3 = true;
      } else {
        this.Reasonhidden = true;
      }
    },
    onOther() {
      this.reason1Show = true;
      if (this.excuses[0].reason == "Other") {
        this.other1 = true;
      } else {
        this.other1 = false;
      }
      if (this.excuses[0].reason == "") {
        this.R1 = false;
      } else {
        this.R1 = true;
      }
    },
    onOther2() {
      this.reason2Show = true;
      if (this.excuses[1].reason == "Other") {
        this.other2 = true;
      } else {
        this.other2 = false;
      }
      if (this.excuses[1].reason == "") {
        this.R2 = false;
      } else {
        this.R2 = true;
      }
    },
    onOther3() {
      if (this.pricePlan.price_plan_reason == "Other") {
        this.other3 = true;
      } else {
        this.other3 = false;
        this.R4 = true;
      }
      if (this.pricePlan.price_plan_reason == "") {
        this.reason3Show = false;
      } else {
        this.reason3Show = true;
      }
    },

    submitData() {
      // axios
      //   .post(
      //     this.getApiUrl +
      //       "/applicant/approveExcuses/" +
      //       this.applicantData.id.value,
      //     { excuses: this.excuses },
      //     {
      //       headers: {
      //         Authorization: "Bearer " + localStorage.token
      //       }
      //     }
      //   )
      //   .then(response => {
      // if (response.data.status.error == false) {
      // console.log(this.excuses[0].reason_other);
      if (
        this.reason1Show == false ||
        this.reason2Show == false ||
        (this.$v.excuses[0].$invalid && this.applicantData.iqama_in_range) ||
        (this.$v.excuses[1].$invalid && this.applicantData.in_range) ||
        (!this.applicantData.iqama_in_range &&
          !this.applicantData.in_range &&
          this.$v.excuses.$invalid) ||
        (this.excuses[0].reason == "Other" &&
          this.excuses[0].reason_other == "") ||
        (this.excuses[1].reason == "Other" &&
          this.excuses[1].reason_other == "")
      ) {
        if (this.excuses[0].reason == "") {
          this.R1 = false;
        }
        if (this.excuses[1].reason == "") {
          this.R2 = false;
        }
        if (
          this.excuses[0].reason == "Other" &&
          (this.excuses[0].reason_other == "" ||
            this.excuses[0].reason_other == null)
        ) {
          // console.log(this.excuses[0].reason_other);
          this.reason1Show = false;
        } else {
          this.reason1Show = true;
        }
        if (
          (this.excuses[1].reason == "Other" &&
            this.excuses[1].reason_other == "") ||
          this.excuses[1].reason_other == null
        ) {
          this.reason2Show = false;
        } else {
          this.reason2Show = true;
        }

        // alert("there is an error");
      } else {
        if (
          this.data.in_range &&
          this.data.in_range == true &&
          this.data.iqama_in_range &&
          this.data.iqama_in_range == true
        ) {
          // console.log(this.applicant.status);

          this.price_plan_previous = true;
        } else {
          this.price_plan_previous = false;
        }
        this.price_plan = false;
        this.approve_excuses = true;
        this.R1 = true;
        this.R2 = true;
        this.reason1Show = false;
        this.reason2Show = false;
      }

      // }
      // });
    },
    Previous() {
      this.price_plan = true;
      this.approve_excuses = false;
      this.confirmation_message = true;
      this.price_plan_previous = true;
      this.R1 = true;
      this.R2 = true;
      this.reason1Show = true;
      this.reason2Show = true;
    },
    PreviousP() {
      if (
        this.data.in_range &&
        this.data.in_range == true &&
        this.data.iqama_in_range &&
        this.data.iqama_in_range == true
      ) {
        // console.log(this.applicant.status);

        this.price_plan_previous = true;
      } else {
        this.price_plan_previous = false;
      }
      this.price_plan = false;
      this.approve_excuses = true;
      this.confirmation_message = true;
    },
    submitPricePlanData() {
      this.disabledConfirmbtn = true;
      axios
        .post(
          this.getApiUrl + "/applicant/approveExcuses/" + this.applicantData.id,
          { excuses: this.excuses },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            axios
              .post(
                this.getApiUrl +
                  "/applicant/applicantPricePlan/" +
                  this.applicantData.id,
                this.pricePlan,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token
                  }
                }
              )
              .then(response => {
                if (response.data.status.error == false) {
                  //console.log(response.data.status.message);
                  axios
                    .post(
                      this.getApiUrl +
                        "/applicant/updateStatus/" +
                        this.applicantData.id,
                      { status: "approved" },
                      {
                        headers: {
                          Authorization: "Bearer " + localStorage.token
                          //the token is a variable which holds the token
                        }
                      }
                    )
                    .then(response => {
                      // //console.log(response);

                      if (response.data.status.error == false) {
                        // alert("success");
                        this.applicantData.status = "Approved";
                        this.close();
                        // this.modal = "closeModal";
                        $(".modal").modal("toggle");
                        // this.modal1 = "modal";
                        this.disabledConfirmbtn = false;

                        location.reload();
                      } else {
                        alert(response.data.status.message);
                      }
                    })
                    .catch(err => {
                      alert(err);
                    });
                }
              });
          } else {
            if (
              this.data.in_range &&
              this.data.in_range == true &&
              this.data.iqama_in_range &&
              this.data.iqama_in_range == true
            ) {
              // console.log(this.applicant.status);

              this.price_plan_previous = true;
            } else {
              this.price_plan_previous = false;
            }
            this.price_plan = true;
            this.approve_excuses = false;
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
